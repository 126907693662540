import React from "react";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { LoaderOverlay } from "../../components/loader";
import { useReducerState } from "../../hooks.js/useReducerState";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useSingleState } from "../../hooks.js/useSingleState";
import { resetPassword } from "../../utils/api-request";

const ResetPassword2 = () => {
    const { token } = useParams();

    const { addToast } = useToasts();

    const history = useHistory();

    const inputResult = useReducerState({ password: "", confirm_password: "" });

    const submitLoading = useSingleState(false);

    const handleChange = (name, e) => {
        inputResult.set({ [name]: e.target.value });
    };

    const passwordError = useSingleState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const one = inputResult.use.password;
        const two = inputResult.use.confirm_password;
        if (one !== two) {
            passwordError.set("Password does not match");
            return;
        }
        passwordError.set("");

        const dataToSend = {
            token,
            new_password: one,
        };

        submitLoading.set(true);

        try {
            const response = await resetPassword(dataToSend);
            if (response.status.toString().startsWith("2")) {
                submitLoading.set(false);
                addToast(
                    "Your Password reset was successful. You can now proceed to login",
                    {
                        appearance: "success",
                        autoDismiss: true,
                    }
                );
                history.push("/login");
            }
        } catch (error) {
            submitLoading.set(false);
            console.log(error);
            if (!error.response) {
                addToast("Kindly check your network and refresh", {
                    appearance: "error",
                    autoDismiss: true,
                });
            } else if (error.response) {
                addToast(error.response.data.errorMessage, {
                    appearance: "error",
                    autoDismiss: true,
                });
            } else {
                addToast("Something went wrong. Please try again", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
    };

    console.log(token);
    return (
        <div className="bg-white w-screen md:h-screen">
            {submitLoading.use && <LoaderOverlay />}
            <div className="container flex flex-col">
                <nav className="flex items-center text-white pt-5">
                    <h1 className="text-3xl force-black font-medium">
                        Dizbuzz
                    </h1>
                </nav>
                <div className="flex justify-center mt-16">
                    <form
                        onSubmit={handleSubmit}
                        className="w-96 shadow-lg p-8"
                    >
                        <h5 className="text-center force-black font-bold mb-4 text-lg">
                            Choose a new password
                        </h5>

                        <div className="mt-8 mb-3">
                            <label className="force-black font-semibold">
                                New Password
                            </label>
                            <input
                                className="border-2 w-full mt-2 bg-input-gray block px-3 py-3 border-gray-400"
                                type="password"
                                name="password"
                                onChange={(e) => {
                                    handleChange("password", e);
                                }}
                                required
                            />
                        </div>
                        <div className="mt-4">
                            <label className="force-black font-semibold">
                                Confirm New Password
                            </label>
                            <input
                                className="border-2 w-full mt-2 bg-input-gray block px-3 py-3 border-gray-400"
                                type="password"
                                name="confirm_password"
                                onChange={(e) => {
                                    handleChange("confirm_password", e);
                                }}
                                required
                            />
                            {passwordError.use && (
                                <span className="font-normal block mt-2 text-xs text-red-500">
                                    {passwordError.use}
                                </span>
                            )}
                        </div>
                        <div className="flex mt-6 justify-center">
                            <button
                                type="submit"
                                className="py-3 rounded-md text-white cursor-pointer px-6 cusBlue"
                            >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword2;
