export const config = {
    base_url: "https://dizbuzz-loan-backend.onrender.com/api/v1",
    token_name: "LOAN_TOKEN",
};

export const appConfig = {
    // base_url: "https://live.odiopay.com/api/v1",
    // base_url: "http://localhost:8080/api/v1",
    base_url: "https://dizbuzz-loanx-backend-live.onrender.com/api/v1",

    token_name: "LOAN_TOKEN",
    token_name2: "LOAN_CWS_TKN",
};
