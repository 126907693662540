import React, { useContext } from "react";

import { Link } from "react-router-dom";
import Settings from "./settings";

import { AppContext } from "../../context/app_context";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export function AppCustomerHeader() {
    const {
        appState: { user },
    } = useContext(AppContext);
    return (
        <div className="shadow-md fixed z-20 top-0 left-0 right-0 bg-white py-2 pl-5 pr-12 w-full flex items-center">
            {/* <SwitchTransition>
				<CSSTransition
					key={appState.page_title}
					addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
					classNames="fade"
				>
					<h2 className="text-xl font-semibold text-gray-1">{appState.page_title}</h2>
				</CSSTransition>
			</SwitchTransition> */}
            <img
                alt={""}
                className="w-20 ml-5 object-contain"
                src={require("./../../assets/Logo-Sinle-colour.png")}
            />
            <div className="ml-auto" />

            <span className="font-semibold">Dizbuzz finance</span>

            {!user.is_verified && (
                <span className="px-3 rounded py-1 ml-3 font-semibold bg-yellow-300 text-yellow-700">
                    Verify Email
                </span>
            )}

            <Link to={"/customer"}>
                <img
                    className="mx-10 h-4"
                    src={require("./../../assets/bell-solid.svg")}
                    alt="settings"
                />
            </Link>
            <Settings />
            {/* <span className="mr-3 flex items-center" >
				<img
					className="ml-5 mr-3 h-4"
					src={require("./../../assets/settings-24pxx.svg")}
					alt="settings"
				/>
				<span className="rotate-90 transform ml-auto text-lg font-semibold">&rsaquo;</span>
			</span> */}
        </div>
    );
}
